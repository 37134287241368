<template>
    <button @click="openDialog" class="explore-btn hover:bg-sky-800 text-white font-bold py-2 px-4 rounded">
        Explore with us
    </button>
    <div v-if="open === true">
        <PopUpModal class="modal-index">
            <!-- Modal content -->
            <div class="relative bg-white">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5">
                    <h3 class="text-lg font-semibold text-gray-900 ">
                        Cutomized Booking
                    </h3>
                    <button @click="openDialog" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-toggle="crud-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <form class="p-4 md:p-5" @submit.prevent="bookTrip">
                    <div class="grid gap-4 mb-4 grid-cols-2">
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Trip Type</label>
                            <div class="flex">
                                <div class="flex items-center me-4">
                                    <input type="radio" value="round-trip" v-model="trip_type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label class="ms-2 text-sm font-medium text-gray-900">Round Trip</label>
                                </div>
                                <div class="flex items-center me-4">
                                    <input type="radio" value="one-way" v-model="trip_type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label class="ms-2 text-sm font-medium text-gray-900">One Way Trip</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Destination</label>
                            <input type="text" v-model="destination" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. Boracay" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Departure</label>
                            <input type="date" v-model="departure" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. John T. Smith, James V. Doe" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Return</label>
                            <input type="date" v-model="return_from_trip" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. johnsmith@example.com" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">No. of Passengers</label>
                            <input type="number" v-model="no_of_passengers" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. 4" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Name/s</label>
                            <input type="text" v-model="name_of_people" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. John T. Smith, James V. Doe" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">E-mail</label>
                            <input type="email" v-model="email_add" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. johnsmith@example.com" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900 ">Phone Number</label>
                            <input type="tel"  v-model="phone_numbers" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="09123456789" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">Complete Address</label>
                            <input type="text" v-model="address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. Blk 1 lot 1 Sesame St. Brgy San Miguel Quezon City" required="">
                        </div>
                        <div class="col-span-1 sm:col-span-1">
                            <label  class="block mb-2 text-sm font-medium text-gray-900 ">State/Province</label>
                            <input type="text" v-model="state_or_province" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="e.g. Camarines Sur" required="">
                        </div>
                    </div>
                    <button :disabled="!acceptedTC" type="submit"
                        :class="[
                            acceptedTC ? 'bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300' : 'bg-gray-400',
                            'text-white inline-flex items-center font-medium rounded-lg text-sm px-5 py-2.5 text-center'
                        ]" 
                    >
                        <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                        Book
                    </button>
                    &nbsp;
                    <div class="items-center inline-flex">
                        <input id="default-checkbox" type="checkbox" value="" v-model="acceptedTC" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Book? Accept <router-link class="hover:text-blue-500 text-blue-800 underline" :to="{name: 'terms-page'}">Terms & Conditions!</router-link></label>
                    </div>
                </form>
            </div>
        </PopUpModal>
    </div>
</template>

<script>
import PopUpModal from '@/components/PopUpModal.vue'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Parse from 'parse';
Parse.serverURL = 'https://parseapi.back4app.com';
Parse.initialize('A9iU0asj7lBx8THtL3OuHVdKAebIUED9GJKzrpWn', 'AQmS27wK0tZHTPFga30Afa0eMTqnBmzf7zk9yX4F');

export default{
    components: { PopUpModal },
    data(){
        return{
            open: false,
            trip_type: '',
            no_of_passengers: '',
            departure: '',
            return_from_trip: '',
            name_of_people: '',
            email_add: '',
            phone_numbers: '',
            address: '',
            state_or_province: '',
            destination: '',
            acceptedTC: false,
        }
    },

    methods: {
        openDialog(){
            // this.open = !this.open
            // this.$go('https://www.facebook.com/airprove.travelagency')
            window.open('https://www.facebook.com/airprove.travelagency', '_blank');
        },

        bookTrip(){
            const Booked_trip = Parse.Object.extend("custom_booking");
            const booked_trip = new Booked_trip();
            booked_trip.set("destination", this.destination);
            booked_trip.set("trip_type", this.trip_type);
            booked_trip.set("no_of_passengers", this.no_of_passengers);
            booked_trip.set("departure", this.departure);
            booked_trip.set("return_", this.return_from_trip);
            booked_trip.set("name", this.name_of_people);
            booked_trip.set("email", this.email_add);
            booked_trip.set("phone_number", this.phone_numbers);
            booked_trip.set("address", this.address);
            booked_trip.set("state_or_province", this.state_or_province);
            booked_trip.set("status", "pending");
            booked_trip.set("book_notes", "Customized Booking");

            booked_trip.save().then((booked_trip) => {
                console.log(booked_trip.id);
                this.popUp();
                // if(!booked_trip.id){
                //     this.popUp();
                // }
            }, (error) => {
                alert('Error:' + error.message);
            });

            this.open = false;
            this.id_holder = "";
            this.no_of_passengers = "";
            this.departure = "";
            this.return_from_trip = "";
            this.name_of_people = "";
            this.email_add = "";
            this.phone_numbers = "";
            this.address = "";
            this.city = "";
            this.state_or_province = "";
            this.trip_type = "";
            this.acceptedTC = false;
        },

        popUp(){
            Swal.fire({
                icon: 'success', title: "Booked!", text: "Wait for our admin to contact you thru e-mail and SMS",
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
                // timer: 3000,
                // timerProgressBar: true,
            });
        },
    }
}
</script>
<style>
.explore-btn{
    background-color: #1C3F60;
}

.modal-index{
    /* z-index: 40; */
    text-align: start;
    
}
</style>