import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import OutWardBound from '../views/OutwardBound.vue'
import CultureCurious from '../views/CultureCurious.vue'
import RelaxPage from '../views/RelaxPage'
import ServicesPage from '../views/ServicesPage.vue'
import PricingPage from '../views/PricingPage.vue'
import OurCompany from '../views/OurCompany.vue'
import LoginAdmin from '../views/LoginAdmin.vue'
import HomeAdmin from '../views/HomeAdmin.vue'
import DomesticAdmin from '../views/DomesticAdmin.vue'
import InternationalAdmin from '../views/InternationalAdmin.vue'
import { checkAdminStatus } from '../checkAdminStatus.js'
import reloadPage from '../views/reloadPage.vue'
import HolidayAdmin from '../views/HolidayAdmin.vue'
import SpecificTrip from '../views/SpecificTrip.vue'
import employeeJustin from '../views/employee-justin.vue'
import josephLouie from '../views/employee-jolo.vue'
import employeeChristian from '../views/employee-christian.vue'
import NotFound from '../views/NotFound.vue'
import ReviewAdmin from '../views/ReviewAdmin.vue'
import FlightsCatalog from '../views/FlightsCatalog.vue'
import CustomBookings from '../views/CustomBookings.vue'
import TermsConditions from '../views/TermsConditions.vue'
import UpcomingPage from '../views/UpcomingPage.vue'

const adminRoutes = [
  {
    path: '/admin/bookings/cutomized',
    name: 'admin-customized',
    component: CustomBookings,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/bookings',
    name: 'admin-home',
    component: HomeAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/domestic',
    name: 'admin-domestic',
    component: DomesticAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/international',
    name: 'admin-international',
    component: InternationalAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/holiday',
    name: 'admin-holiday',
    component: HolidayAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/reviews',
    name: 'admin-reviews',
    component: ReviewAdmin,
    beforeEnter: adminGuard,
  },
];

const otherRoutes = [ 
    {
        path: '/',
        name: 'Home',
        component: LandingPage,
        meta: { title: 'AirProve | Home'},
    },
    {
        path: '/Specialties/Outward-bound',
        name: 'outward',
        component: OutWardBound,
        meta: { title: 'AirProve | Ourward-bound'},
    },
    {
        path: '/Specialties/Culture-curious',
        name: 'culture',
        component: CultureCurious,
    },
    {
        path: '/Specialties/Relax-Relate-Release',
        name: 'relax',
        component: RelaxPage,
    },
    {
        path: '/Services',
        name: 'services',
        component: ServicesPage,
    },
    {
        path: '/Pricing',
        name: 'pricing',
        component: PricingPage,
    },
    {
        path: '/Our-team',
        name: 'team',
        component: OurCompany,
    },
    {
        path: '/login/admin',
        name: 'login-admin',
        component: LoginAdmin,
    },
    {
      path: '/reload',
      name: 'reload-page',
      component: reloadPage,
    },
    {
      path: '/booking/destination=:destination/id=:id',
      name: 'specific-trip',
      component: SpecificTrip,
    },
    {
      path: '/employee-profile/john-justin-berdin',
      name: '/employee-profile/justin',
      component: employeeJustin,
    },
    {
      path: '/employee-profile/joseph-louie-solcoretes',
      name: '/employee-profile/jolo',
      component: josephLouie,
    },
    {
      path: '/employee-profile/christian-james-morisca',
      name: '/employee-profile/christian',
      component: employeeChristian,
    },
    {
      path: '/flights/:fromLoc/:toLoc/:departureDate/:returnDate',
      name: 'flightsCatalog',
      component: FlightsCatalog,
    },
    {
      path: '/TermsAndConditions',
      name: 'terms-page',
      component: TermsConditions,
    },
    {
      path: '/Coming-soon',
      name: 'upcomingPage',
      component: UpcomingPage,
    },
    { 
      path: '/:catchAll(.*)', 
      component: NotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [...otherRoutes, ...adminRoutes],
    scrollBehavior() {
      // Scroll to the top of the page on route navigation
      return { top: 0, left: 0 };
    }
});

router.afterEach((to) => {
  document.title = to.meta.title || 'AirProve Travel Agency';
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  // Your global logic here (if needed)
  next();
});
async function adminGuard(to, from, next) {
  try {
    console.log('Entering adminGuard');
    const isAdmin = await checkAdminStatus();

    if (isAdmin) {
      console.log('User is an admin, allow navigation');
      next();
    } else {
      console.log('User is not authorized, redirecting to /login/admin');
      next('/login/admin');
    }
  } catch (error) {
    console.error('Error checking admin status:', error);
    console.log('Redirecting to /login/admin due to error');
    next('/login/admin'); // Handle error by redirecting to the login page
  }
}

export default router